<template>
  <div>
    <ba-dialog
      :show="dialog"
      v-on:show-dialog="dialog = true"
      btnColor="success"
      :btnName="$t('_createContact')"
      :btnTooltip="$t('_createContact')"
      :title="$t('_createContact')"
    >
      <v-container fluid grid-list-xl>
        <v-layout wrap align-center>
          <v-flex xs12>
            <v-flex xs12>
              <v-text-field :label="$t('_remoteId')" v-model="remoteIdentification"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="Name" v-model="name"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                :items="validAccountingConnections"
                item-value="id"
                v-model="connectionId"
                label="Accounting Connection"
              >
                <template v-slot:selection="{ item }">
                  <img class="tw-h-6 tw-mr-3" :src="getProviderIcon(item.provider)" />{{ getConnectionName(item) }}
                </template>
                <template v-slot:item="{ item }">
                  <img class="tw-h-6 tw-mr-3" :src="getProviderIcon(item.provider)" />{{ getConnectionName(item) }}
                </template>
              </v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="First Name" v-model="firstName"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="Last Name" v-model="lastName"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="Email Address" v-model="emailAddress"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select :items="contactTypes" :label="$tc('_contactTypes')" v-model="type"></v-select>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-btn @click="save" color="primary text-capitalize" :disabled="!this.canSave">{{ $t('_save') }}</v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
    </ba-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import { ConnectionCategory, ConnectionStatus, Providers } from '@/api-svc-types';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';

export default {
  props: ['refresh'],
  data: () => ({
    connectionId: 'Manual',
    contactTypes: ['None', 'Customer', 'Vendor'],
    type: '',
    name: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    remoteIdentification: '',
    dialog: false,
    connections: [],
  }),
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      update(data) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
  computed: {
    canSave() {
      var canSave = false;
      if (this.name && this.type) {
        canSave = true;
      }
      return canSave;
    },
    validAccountingConnections() {
      const accountingConnections = this.connections.filter((c) => {
        return c.category === ConnectionCategory.AccountingConnection && !c.isDisabled;
      });
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      return accountingConnections.concat(manualAccountingConnection);
    },
  },
  methods: {
    async save() {
      const orgId = this.$store.state.currentOrg.id;

      const contact = {
        type: this.type,
        name: this.name,
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.emailAddress,
        remoteId: this.remoteIdentification,
        connectionId: this.connectionId,
      };

      const vars = {
        orgId,
        contact,
      };

      this.reset();

      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $contact: CreateContactInput!) {
            createContact(orgId: $orgId, contact: $contact)
          }
        `,
        variables: vars,
      });

      this.closeDialog();
    },
    closeDialog() {
      this.reset();
      this.refresh();
      this.dialog = false;
    },
    reset() {
      this.type = '';
      this.name = '';
      this.firstName = '';
      this.lastName = '';
      this.emailAddress = '';
      this.remoteIdentification = '';
      this.connectionId = 'Manual';
    },
    getProviderIcon(provider) {
      return getAccountingProviderIcon(provider);
    },
    getConnectionName(conn) {
      let name = conn.name;
      if (conn.provider === 'Manual') {
        name = 'Bitwave';
      }

      if (!name) {
        name = conn.provider;
      }

      return name;
    },
  },
};
</script>
