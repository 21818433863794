


























































































































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

import type { Category, Connection, Contact, ContactAddress, Providers } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';
import { asDefined } from '@/utils/guards';

import { validAccountingConnections } from '../transactions/ConnectionUtils';

interface network {
  name: string;
  networkId: string;
}

@Component
export default class ContactAddresses extends BaseVue {
  @Watch('dialog')
  public onContactChange() {
    this.revenueCategory = this.filteredCategories.find(
      (category) => category.id === this.contact.defaultRevenueCategoryId
    );
    this.expenseCategory = this.filteredCategories.find(
      (category) => category.id === this.contact.defaultExpenseCategoryId
    );
  }

  @Prop({ required: true })
  public readonly contact!: Contact;

  public get addresses(): ContactAddress[] {
    return asDefined(this.contact.addresses);
  }

  public get categories(): Category[] {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public get filteredContacts(): Contact[] {
    if (!this.contacts) return [];
    if (this.contact.accountingConnectionId) {
      return this.contacts.filter((c) => c.accountingConnectionId === this.contact.accountingConnectionId);
    }

    return this.contacts;
  }

  public get filteredCategories(): Category[] {
    if (!this.categories) {
      return [];
    }
    if (this.contact.accountingConnectionId) {
      return this.categories
        .filter((c) => c.accountingConnectionId === this.contact.accountingConnectionId)
        .sort((a, b) => {
          //  we will try to sort by the account code
          if (typeof a.code === 'number' && typeof b.code === 'number') {
            return Number(a.code) - Number(b.code);
          }

          //  default is a string comparison on name
          return a.name.localeCompare(b.name);
        });
    }

    return this.categories;
  }

  // From Apollo
  public connections: Connection[] = [];

  public get title() {
    return this.contact.name + ' ' + this.$tc('_address', 2);
  }

  public dialog = false;
  public isLoading = false;
  public selected: ContactAddress[] = [];

  public revenueCategory: Category | undefined = { name: '', id: '' };
  public expenseCategory: Category | undefined = { name: '', id: '' };

  public newAddrDefaultRevenueCategory: Category | undefined = { name: '', id: '' };
  public newAddrDefaultExpenseCategory: Category | undefined = { name: '', id: '' };
  public newAddr: Partial<ContactAddress> = {};

  @Ref()
  public readonly newAddrForm!: { resetValidation(): void; validate(): boolean };

  public async changeDefaultCategory(revenue: boolean) {
    let categoryId;
    if (revenue) categoryId = this.revenueCategory?.id;
    else categoryId = this.expenseCategory?.id;
    this.$emit('default-category-update', this.contact, revenue, categoryId);
  }

  public async addAddr(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (!this.newAddrForm.validate()) {
      return;
    }

    if (this.newAddrDefaultRevenueCategory?.id)
      this.newAddr.defaultRevenueCategoryId = this.newAddrDefaultRevenueCategory?.id;

    if (this.newAddrDefaultExpenseCategory?.id)
      this.newAddr.defaultExpenseCategoryId = this.newAddrDefaultExpenseCategory?.id;
    await this.save([...this.addresses, this.newAddr as ContactAddress]);

    this.contact.addresses.push(this.newAddr as ContactAddress);
    this.$emit('addressChanged');
    this.newAddr = {};
    this.newAddrForm.resetValidation();
  }

  public async deleteSelected() {
    const newAdresses = this.addresses.filter((x) => !this.selected.includes(x));
    await this.save(newAdresses);
    this.contact.addresses = newAdresses;
  }

  public getConnectionById(connectionId?: string | null) {
    return this.getValidAccountingConnections.filter((connection) => connection.id === connectionId)[0];
  }

  public get getValidAccountingConnections() {
    return validAccountingConnections(this.connections, this.filteredContacts, this.filteredCategories);
  }

  public getProviderIcon(provider: Providers) {
    return getAccountingProviderIcon(provider);
  }

  public getCategoryNameById(searchId: string): string {
    return this.categories.find((category) => category.id === searchId)?.name ?? searchId;
  }

  public async save(addresses: ContactAddress[]) {
    this.isLoading = true;
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $contactId: ID!, $addresses: [ContactAddressInput!]!) {
            setContactCoinAddresses(orgId: $orgId, contactId: $contactId, addresses: $addresses)
          }
        `,
        variables: {
          orgId: this.$store.state.currentOrg.id,
          contactId: this.contact.id,
          addresses: addresses.map((x) => ({ ...x, __typename: undefined })),
        },
      });
    } catch (e) {
      this.showErrorSnackbar('An error occurred');
      console.error(e);
      return;
    } finally {
      this.isLoading = false;
    }
  }

  get alphabeticalNetworks() {
    return this.$store.getters.networks.sort((a: network, b: network) => a.name.localeCompare(b.name));
  }
}
